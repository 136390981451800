<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    transition="scale-transition"
    offset-y
    bottom
    left
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="c-input-xs input-filter"
        :class="{ 'c-input-has-sort': hasSort }"
        v-model="dateFormatted"
        :label="label"
        :placeholder="placeholder"
        :prepend-inner-icon="sortPrependIcon"
        @click:prepend-inner="sortClick"
        clearable
        hide-details
        outlined
        dense
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="clearDates"
      ></v-text-field>
    </template>
    <v-date-picker v-model="dates" range no-title scrollable locale="vi-vn">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="saveDate">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { debounce } from "lodash/function";
export default {
  name: "DateRangeFilter",
  props: {
    hasSort: {
      type: Boolean,
      default: () => false,
    },
    name: {
      type: String,
      default: () => "",
    },
    sortName: {
      type: String,
      default: () => "",
    },
    sorting: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    defaultDate: {
      type: [Array, String],
      default: () => null,
    },
    defaultValue: {
      type: [Array, String],
      default: () => null,
    },
  },
  data: () => ({
    value: null,
    sortValue: null,
    dates: null,
    menu: null,
    dateFormatted: null,
  }),
  computed: {
    sortPrependIcon() {
      if (!this.hasSort) {
        return "";
      }
      switch (this.sortValue) {
        case "asc":
          return "mdi-arrow-up";
        case "desc":
          return "mdi-arrow-down";
        default:
          return "mdi-arrow-up-down";
      }
    },
  },
  watch: {
    dates() {
      this.dateFormatted =
        this.dates && this.dates[0] && this.dates[1]
          ? this.formatDateTime(this.dates[0], "DD/MM/YYYY") +
            " - " +
            this.formatDateTime(this.dates[1], "DD/MM/YYYY")
          : null;
    },
    sorting(val) {
      if (val !== this.sortName) {
        this.sortValue = null;
      }
    },
  },
  methods: {
    onFilter: debounce(function () {
      this.$emit("onFilter", {
        name: this.name,
        value: this.dates,
      });
    }, 500),
    saveDate() {
      this.$refs.menu.save(this.dates);
      this.onFilter();
    },
    clearDates() {
      this.dates = null;
      this.onFilter();
    },
    sortClick() {
      if (!this.sortValue) {
        this.sortValue = "asc";
      } else if (this.sortValue === "asc") {
        this.sortValue = "desc";
      } else if (this.sortValue === "desc") {
        this.sortValue = "asc";
      }
      this.$emit("onSort", {
        sort_by: this.sortName,
        sort_type: this.sortValue,
      });
    },
  },
  mounted() {
    /* if (this.defaultDate && this.defaultDate.length > 0) {
      this.dates = [...this.defaultDate]
      this.$refs.menu.save(this.dates)
    } */
    if (this.defaultValue && this.defaultValue.length > 0) {
      this.dates = [...this.defaultValue];
      this.$refs.menu.save(this.dates);
      this.onFilter();
    }
  },
};
</script>
